import React from 'react';
import styles from './SupportUs.module.css';
//Changed 1
function SupportUs() {
  return (
    <div className={styles.supportUs}>
      <h1>Support AIPlayer Next-Gen Cybersec</h1>
      <p className={styles.intro}>
        Join us in revolutionizing cybersecurity with AI. Your support will help us bring this groundbreaking tool to market and make the digital world safer for everyone.
      </p>

      <div className={styles.cta}>
        <h2>Ready to Support Us ?</h2>
        <p>Head over to our Kickstarter page to back the project and claim your rewards!</p>
        <a 
          href="http://kck.st/4eKaBJS" 
          className={styles.ctaButton} 
          target="_blank" 
          rel="noopener noreferrer"
        >
          Support on Kickstarter
        </a>
      </div>
    </div>
  );
}

export default SupportUs;