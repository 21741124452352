import React, { useState } from 'react';
import { useMsal } from "@azure/msal-react";
import styles from './Signup.module.css';  // Import the new CSS file
import axios from 'axios';

function Signup() {
  const { instance } = useMsal();
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    fullName: '',
    companyName: '',
    password: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSignup = async () => {
    if (isSigningUp) return;

    setIsSigningUp(true);

    try {
      // Perform Azure B2C sign-up
      const response = await instance.loginPopup();
      console.log('Sign-Up successful:', response);

      const userEmail = response.account.username;
      const companyName = formData.companyName;
      const fullName = formData.fullName;

      // Call your Stripe function app to initiate payment
      const stripeSession = await axios.post('https://stripefunctionapi.azurewebsites.net', {
        email: userEmail,
        company: companyName,
        name: fullName
      });

      // Redirect user to Stripe checkout page
      window.location.href = stripeSession.data.url;

    } catch (error) {
      console.error('Sign-Up failed', error);
    } finally {
      setIsSigningUp(false);
    }
  };

  return (
    <div className={styles.signup}>
      <h1>Sign Up</h1>
      <div className={styles.formGroup}>
        <label htmlFor="fullName">Full Name</label>
        <input 
          type="text" 
          id="fullName" 
          name="fullName" 
          value={formData.fullName}
          onChange={handleInputChange} 
          required 
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="email">Email</label>
        <input 
          type="email" 
          id="email" 
          name="email" 
          value={formData.email}
          onChange={handleInputChange} 
          required 
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="companyName">Company Name</label>
        <input 
          type="text" 
          id="companyName" 
          name="companyName" 
          value={formData.companyName}
          onChange={handleInputChange} 
          required 
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="password">Password</label>
        <input 
          type="password" 
          id="password" 
          name="password" 
          value={formData.password}
          onChange={handleInputChange} 
          required 
        />
      </div>
      <button onClick={handleSignup} className={styles.submitButton} disabled={isSigningUp}>
        {isSigningUp ? 'Signing up...' : 'Sign Up'}
      </button>
      <p className={styles.signupText}>
        Already have an account? <a href="/login">Login here</a>
      </p>
    </div>
  );
}

export default Signup;
