import React, { useState, useEffect } from 'react';
import styles from './Countdown.module.css';

function Countdown() {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  function calculateTimeLeft() {
    const difference = +new Date('2025-01-01') - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  }

  return (
    <div className={styles.countdown}>
      <h2>Launching in:</h2>
      <div className={styles.timer}>
        <div>{timeLeft.days} <span>days</span></div>
        <div>{timeLeft.hours} <span>hours</span></div>
        <div>{timeLeft.minutes} <span>minutes</span></div>
        <div>{timeLeft.seconds} <span>seconds</span></div>
      </div>
    </div>
  );
}

export default Countdown;