import React, { useState } from 'react';
import styles from './Contact.module.css';

function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://gray-grass-0a4c27e10.5.azurestaticapps.net/api/SendEmailFunction', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to: 'info@aiplayercybersec.com',  // Your email address or recipient email
          from: email,
          subject: `New message from ${name}`,
          text: message,
        }),
      });

      if (response.ok) {
        setStatus('Email sent successfully!');
        setName('');
        setEmail('');
        setMessage('');
      } else {
        setStatus('Failed to send email. Please try again later.');
        console.error('Error:', response.statusText);
      }
    } catch (error) {
      console.error('Error sending email', error);
      setStatus('Failed to send email. Please try again later.');
    }
  };

  return (
    <div className={styles.contact}>
      <h1>Contact Us</h1>
      <p>We'd love to hear from you. Please fill out the form below or reach out to us directly.</p>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.formGroup}>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
        </div>
        <button type="submit" className={styles.submitButton}>Send Message</button>
        {status && <p className={styles.status}>{status}</p>} {/* Display email status */}
      </form>
      <div className={styles.contactInfo}>
        <h2>Direct Contact</h2>
        <p>Email: info@aiplayercybersec.com</p>
      </div>
    </div>
  );
}

export default Contact;
