import React, { useEffect, useState } from 'react';
import axios from 'axios';

const SuccessPage = () => {
  const [isPaymentConfirmed, setIsPaymentConfirmed] = useState(false);

  useEffect(() => {
    const sessionId = new URLSearchParams(window.location.search).get('session_id');
    
    if (sessionId) {
      // Call your backend to confirm Stripe payment (optional)
      axios.post('/api/payment-success', { sessionId })
        .then(response => {
          if (response.data.success) {
            setIsPaymentConfirmed(true);
          }
        })
        .catch(err => {
          console.error('Error confirming payment:', err);
        });
    }
  }, []);

  return (
    <div>
      {isPaymentConfirmed ? (
        <div>
          <h1>Payment Successful!</h1>
          <p>Please install the gateway and log in again. You will receive an email with your gateway credentials and installation instructions.</p>
        </div>
      ) : (
        <div>
          <h1>Confirming Payment...</h1>
        </div>
      )}
    </div>
  );
};

export default SuccessPage;
