import { PublicClientApplication, LogLevel } from "@azure/msal-browser";

const msalConfig = {
    auth: {
        clientId: "865cae92-fe76-4421-8c7e-bf1127bacc5b", // Your Application (client) ID from Azure AD B2C
        authority: "https://querymindsad.b2clogin.com/querymindsad.onmicrosoft.com/B2C_1_SIGNUP_SIGNIN", // B2C flow URL
        knownAuthorities: ["querymindsad.b2clogin.com"], // Your B2C domain
        redirectUri: "https://aiplayercybersec.com/auth-callback", // Your app’s redirect URI after B2C
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) return;
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        break;
                    case LogLevel.Info:
                        console.info(message);
                        break;
                    case LogLevel.Verbose:
                        console.debug(message);
                        break;
                    case LogLevel.Warning:
                        console.warn(message);
                        break;
                    default:
                        console.log(message); // Handle any unexpected log levels
                        break;
                }
            },
            piiLoggingEnabled: false,
            logLevel: LogLevel.Verbose,
        },
    },
};

export const msalInstance = new PublicClientApplication(msalConfig);
