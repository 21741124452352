import React from 'react';
import styles from './About.module.css';

function About() {
  return (
    <div className={styles.about}>
      <h1>About AIPlayer Next-Gen Cybersec</h1>
      <p className={styles.intro}>AIPlayer Next-Gen Cybersec is a revolutionary AI-powered tool designed to enhance cybersecurity practices through automated vulnerability exploitation and prevention.</p>
      <div className={styles.mission}>
        <h2>Our Mission</h2>
        <p>To create a safer digital world by leveraging cutting-edge AI technology in cybersecurity, empowering organizations to stay ahead of evolving threats.</p>
      </div>
      <div className={styles.team}>
        <h2>Our Team</h2>
        <p>We are a diverse group of cybersecurity experts, AI specialists, and software engineers united by our passion for innovation and commitment to digital security.</p>
      </div>
      <div className={styles.values}>
        <h2>Our Values</h2>
        <ul>
          <li>Innovation: Pushing the boundaries of what's possible in cybersecurity</li>
          <li>Integrity: Maintaining the highest ethical standards in all our operations</li>
          <li>Collaboration: Working together to create powerful, user-friendly solutions</li>
          <li>Continuous Learning: Staying at the forefront of cybersecurity and AI advancements</li>
        </ul>
      </div>
    </div>
  );
}

export default About;