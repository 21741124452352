import React, { useState } from 'react';
import { useMsal } from "@azure/msal-react";
import axios from 'axios';  // For calling Stripe API and check-user API
import { useNavigate } from "react-router-dom";  // For navigation
import styles from './Login.module.css';

function Login() {
  const { instance } = useMsal();
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();  // For redirection

  const handleAuth = async () => {
    if (isProcessing) return;  // Prevent multiple clicks
    setIsProcessing(true);
  
    try {
      // Redirect to Azure B2C sign-in/sign-up (same window, no pop-up)
      await instance.loginRedirect();
  
      // After successful B2C sign-up or sign-in, retrieve the user email
      const account = instance.getAllAccounts()[0];
      const userEmail = account.username;
  
      // Call your backend to check if the user is signing up for the first time
      const response = await axios.post('https://stripefunctionapi.azurewebsites.azurewebsites.net/api/check_user', {
        email: userEmail,
      });
  
      if (response.data.isNewUser) {
        // If it's a new user (sign-up), initiate Stripe payment
        const stripeResponse = await axios.post('https://stripefunctionapi.azurewebsites.net/api/create_stripe_session', {
          email: userEmail,
          price_id: 'your_price_id_based_on_selection' // Add logic to pass the correct price_id
        });
  
        // Redirect to Stripe payment page
        window.location.href = stripeResponse.data.url;
      } else {
        // If it's an existing user (sign-in), redirect to the dashboard
        navigate("/dashboard");  // Replace with your dashboard page
      }
  
    } catch (error) {
      console.error('Error during authentication or payment initiation:', error);
    } finally {
      setIsProcessing(false);
    }
  };
  

  return (
    <div className={styles.loginContainer}>
      <div className={styles.login}>
        <h1>Welcome Back!</h1>
        <p className={styles.tagline}>
          Login or sign up to continue using our AI-powered penetration testing tool.
        </p>

        <button 
          onClick={handleAuth}  // Only one button for both sign-in and sign-up
          className={styles.submitButton} 
          disabled={isProcessing}
        >
          {isProcessing ? 'Processing...' : 'Click Here to Continue'}
        </button>
      </div>
    </div>
  );
}

export default Login;
