import React, { useState } from 'react';

const CheckoutPage = () => {
  const [selectedProduct, setSelectedProduct] = useState('');  // State for selected product

  const handleProductSelection = (event) => {
    setSelectedProduct(event.target.value);  // Set the selected product when changed
  };

  const submitCheckout = () => {
    // Handle the product selection logic here
    console.log("Selected Product:", selectedProduct);
    // Continue with your checkout logic
  };

  return (
    <div>
      <h1>Select Your Plan</h1>
      <div>
        <label>
          <input
            type="radio"
            name="product"
            value="year"
            onChange={handleProductSelection}
          />
          Yearly Subscription ($800)
        </label>
        <label>
          <input
            type="radio"
            name="product"
            value="monthly"
            onChange={handleProductSelection}
          />
          Monthly Subscription ($75/month)
        </label>
        <label>
          <input
            type="radio"
            name="product"
            value="one-time"
            onChange={handleProductSelection}
          />
          One-Time Month Payment ($100)
        </label>
      </div>

      <button onClick={submitCheckout}>Proceed to Checkout</button>
    </div>
  );
};

export default CheckoutPage;
