import React from 'react';
import Countdown from '../../components/Countdown/Countdown';
import styles from './Home.module.css';

function Home() {
  return (
    <div className={styles.home}>
      <h1>Welcome to AIPlayer Next-Gen Cybersec</h1>
      <p className={styles.tagline}>Empowering cybersecurity with AI-driven vulnerability exploitation and prevention.</p>
      <Countdown />
      <div className={styles.features}>
        <div className={styles.feature}>
          <h3>AI-Powered Exploitation</h3>
          <p>Harness the power of artificial intelligence to identify and exploit vulnerabilities with unprecedented accuracy.</p>
        </div>
        <div className={styles.feature}>
          <h3>Automated Defense</h3>
          <p>Proactively defend against threats with our intelligent system that learns and adapts to new attack vectors.</p>
        </div>
        <div className={styles.feature}>
          <h3>User-Friendly Interface</h3>
          <p>Access advanced cybersecurity tools through an intuitive, easy-to-use dashboard designed for professionals of all levels.</p>
        </div>
      </div>
    </div>
  );
}

export default Home;