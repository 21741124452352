import React, { useEffect, useState } from 'react';
import { useMsal } from "@azure/msal-react";
import axios from 'axios';  // For Stripe API call
import { useNavigate } from 'react-router-dom';

const AuthCallback = () => {
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    const handleRedirect = async () => {
      setIsProcessing(true);

      try {
        // Step 1: Get the authenticated user info from MSAL
        const activeAccount = accounts[0];
        const userEmail = activeAccount.username;

        // Step 2: Create Stripe session for payment
        const stripeResponse = await axios.post('https://stripefunctionapi.azurewebsites.net/api/create-stripe-session', {
          email: userEmail
        });

        // Step 3: Redirect user to Stripe for payment
        window.location.href = stripeResponse.data.url;

      } catch (error) {
        console.error('Error during redirect or payment initiation:', error);
        // You can handle this error and show a message to the user if needed
      } finally {
        setIsProcessing(false);
      }
    };

    // Only handle the redirect if accounts are available
    if (accounts && accounts.length > 0) {
      handleRedirect();
    }
  }, [accounts, instance, navigate]);

  return (
    <div>
      <h1>Processing authentication...</h1>
      {isProcessing && <p>Redirecting you to the payment page...</p>}
    </div>
  );
};

export default AuthCallback;
