import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import SupportUs from './pages/SupportUs/SupportUs';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import styles from './App.module.css';
import Login from './components/Login/Login';  // Import Login component
import Signup from './components/Signup/Signup';  // Import Signup component
import AuthCallback from './pages/AuthCallback';
import SuccessPage from './components/Success/Success';
import CheckoutPage from './components/CheckoutPage/CheckoutPage';


function App() {
  return (
    <Router>
      <div className={styles.app}>
        <Header />
        <nav className={styles.nav}>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
            <li><Link to="/support">Support Us</Link></li>
            <li><Link to="/login">Login</Link></li>
            
          </ul>
        </nav>
        <main className={styles.main}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/support" element={<SupportUs />} />
            <Route path="/login" element={<Login />} />  {/* Login Route */}
            <Route path="/auth-callback" element={<AuthCallback />} />
            <Route path="/signup" element={<Signup />} />  {/* Signup Route */}
            <Route path="/success" element={<SuccessPage />} />
            <Route path="/checkout" element={<CheckoutPage />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
